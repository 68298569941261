/* hamburger */
/* mobile menu tree open/close */
jQuery(document).ready(function() {
    var hamburger = jQuery('#hamburger-icon');
    hamburger.click(function() {
        hamburger.toggleClass('active');
        return false;
    });
    /* hamburger */
    jQuery('.navbar-toggle').click(function() {
        jQuery(".navbar-toggle").toggleClass("active");
        jQuery(".menu-cta").toggleClass("active");
        jQuery('#hamburger-icon').toggleClass('active');
        jQuery('#navbar').toggle(380);
    });
    jQuery('.navbar-toggle-lang').click(function() {
        jQuery(".navbar-toggle-lang").toggleClass("active");
        jQuery('#navbar2').toggle(380);
        jQuery('.after-lang-menu').toggle(0);

    });

    jQuery('#hamburger-icon').click(function() {
        jQuery(".navbar-toggle").toggleClass("active");
        jQuery(".menu-cta").toggleClass("active");
        jQuery('#navbar').toggle(380);
    });
    /* open / close clicked tree */
    jQuery('label.tree-toggler').click(function() {
        jQuery(this).toggleClass("open");
        jQuery(this).parent().children('ul.tree').toggle(350);
    });
    /* move CTA buttons to bottom or top */
});
/*close all trees */
jQuery(document).ready(function() {
    jQuery('label.tree-toggler').parent().children('ul.tree').toggle(300);
});